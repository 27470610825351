import React, { Component } from 'react';

class Header extends Component {
    render() {
        return (

            <header className="header">
                <div className="logo-container">
                    <a href="../4.0.0" className="logo">
                        <img src="../img/logos/logo.png" width="100" height="35" alt="Porto Admin" />
                    </a>
                    <div className="d-md-none toggle-sidebar-left" data-toggle-class="sidebar-left-opened" data-target="html" data-fire-event="sidebar-left-opened">
                        <i className="fas fa-bars" aria-label="Toggle sidebar"></i>
                    </div>
                </div>
                <div className="header-right">
                    <span className="separator" />

                    <ul className="notifications">
                        <li>
                            <a href="#" className="dropdown-toggle notification-icon" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="bx bx-list-ol"></i>
                                <span className="badge">3</span>
                            </a>
                            {/* Notifications dropdown content */}
                        </li>
                        <li>
                            <a
                                href="#"
                                className="dropdown-toggle notification-icon"
                                data-bs-toggle="dropdown"
                            >
                                <i className="bx bx-envelope" />
                                <span className="badge">4</span>
                            </a>
                            {/* Messages dropdown content */}
                        </li>
                        <li>
                            <a
                                href="#"
                                className="dropdown-toggle notification-icon"
                                data-bs-toggle="dropdown"
                            >
                                <i className="bx bx-bell" />
                                <span className="badge">3</span>
                            </a>
                            {/* Alerts dropdown content */}
                        </li>
                    </ul>
                    <span className="separator" />
                    <div className="userbox">
                        <a href="#" data-bs-toggle="dropdown">
                            <figure className="profile-picture">
                                <img src="img/!logged-user.jpg" alt="Kalyan Kumar KT" className="rounded-circle" data-lock-picture="img/!logged-user.jpg" />
                            </figure>
                            <div className="profile-info" data-lock-name="Kalyan Kumar KT" data-lock-email="kalyan.kt@example.com">
                                <span className="name">RAJI M</span>
                                <span className="role">Administrator</span>
                            </div>
                            <i className="fa custom-caret" />
                        </a>
                        {/* User dropdown content */}
                        <div className="dropdown-menu">
                            {/* Dropdown items go here */}
                            <a className="dropdown-item" href="#">
                                Profile
                            </a>
                            <a className="dropdown-item" href="#">
                                Settings
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="#">
                                Logout
                            </a>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}


export default Header;

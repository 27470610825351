import React, { Component } from "react";
import axios from "axios";
import swal from 'sweetalert';

class PANValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PanNumber: "",
      Name:""
     
    };
  }

  handlePanNumberChange = (event) => {
    this.setState({ PanNumber: event.target.value });
  };
  handleNameChange = (event) => {
    this.setState({ Name: event.target.value });
  };

  handleVerifyClick = () => {
    const {PanNumber,Name}=this.state;
    const baseApiUrl = process.env.REACT_APP_API_URL;
    const apiUrl =`${baseApiUrl}Home/GetPanDetailByPanNumber?PanNumber=${this.state.PanNumber}&Name=${this.state.Name}`;
    axios
      .get(apiUrl)
      .then((response) => {
        if (!response.data) {
          throw new Error("Network response was not ok");
        }

        // Check if the response is null or 0
       if(response.data.message=='Insufficient balance to pay credits.')
       {

     
          swal({
            title: "Insufficient Balance",
            text: "Please recharge your account.",
            icon: "error",
          });
        }
        else{

      
          this.setState({
            apiResponse: response.data,
            error: null,
          });
        }
      })
      .catch((error) => {
        this.setState({
          apiResponse: undefined,
          error: error.message,
        });
      });
  };

  render() {
    return (
      <div className="row">
        <div className="col">
          <section className="card">
            <header className=" card-header">
              <h2 className="card-title" style={{ color: "#0088cc" }}>
                PAN Verification (Paid: 5 Credit Charged)
              </h2>
            </header>
            <div className="card-body">
              <div className="form-group row pb-1">
                <div className="col-lg-6">
                  <label htmlFor="txtCompanyPAN" className="form-label">
                    PAN Number
                  </label>
                  <input
                    id="txtCompanyPAN"
                    type="text"
                    className="form-control"
                    placeholder="Enter PAN Number"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.PanNumber}
                    onChange={this.handlePanNumberChange}
                  />
                </div>
             
              <div className="col-lg-6">
                  <label htmlFor="txtAnotherCompanyPAN" className="form-label">
                    Name
                  </label>
                  <input
                    id="txtAnotherCompanyPAN"
                    type="text"
                    className="form-control"
                    placeholder="Enter Name"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.Name}
                    onChange={this.handleNameChange}
                  />
                </div>
                </div>
              <div className="form-group row">
                <div className="col-lg-6 offset-lg-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleVerifyClick}
                  >
                    Verify
                  </button>
                </div>
              </div>
           </div>

</section>
</div>
<div
          className="row"
          style={{
            paddingRight: -1,
            marginRight: 387,
            paddingTop: 41,
          }}
        >
                    <section className="card">
                        <header className="card-header">
                        <h2 className="card-title" style={{ color: '#0088CC' }}>
                          PAN Verification Number
                        </h2>
                        </header>
                        <div className="card-body">
                        {this.state.apiResponse !== undefined && (
                                        <div className="response-container">
                                        
                                        <h3>{this.state.apiResponse.message}</h3>
                                        
                                        </div>
                                    )}
                                   
                                    </div> 
                                    </section>
                                    </div>         
            </div>
         
    );
  }
}

export default PANValidation;

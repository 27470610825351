import React from "react";
import axios from "axios";
import { error, event } from "jquery";
import { Component } from "react";
import swal from "sweetalert";
class DLValidationCheck extends Component{
    constructor(props){
    super(props);
    this.state={
        DLNumber:"",
        dobDL:"",
        apiResponse:null,
        error:null,
    };
    
    }
    
    handelDLNumberChange=(event)=>{
        this.setState({DLNumber: event.target.value});
    };
    handeldobDBNumberChange = (Event) => {
        this.setState({ dobDL: Event.target.value });
      };
      
    handelVerifyClick=()=>{
      const baseApiUrl = process.env.REACT_APP_API_URL;
        const{DLNumber,dobDL}=this.state;
        const apiUrl= `${baseApiUrl}DrivingLicense/GetDLValidationCheck?DLNumber=${DLNumber}&dobDL=${dobDL}`;
        axios
        .get(apiUrl)
        .then((response)=>{
            if(!response.data){
                throw new error("networ response was not ok");
            }
            if(response.data==1)
        {
 
      
           swal({
             title: "Insufficient Balance",
             text: "Please recharge your account.",
             icon: "error",
           });
         }
         else{
 
       
           this.setState({
             apiResponse: response.data,
             error: null,
           });
         }
       })
        .catch((error)=>{
            this.setState({
                apiResponse:undefined,
                error:error.message,
            });
        });
    };
    render(){
        return(
            <div className="row">
                <div className="col">
                    <section className="card">
                        <header className="card-header">
                            <h2 className="card-tittle" style={{color:"#0088cc"}}>
                                Driving license Validation(Paid : 5 creidt Charged)
                            </h2>
                        </header>
                        <div className="card-body">
                        <div className="form-group row pb-1">
                    <div className="col-lg-6">
                      <label htmlFor="txtDLCode" className="form-label">
                        DL Number
                      </label>
                      <input
                        id="txtDLCode"
                        type="text"
                        className="form-control"
                        placeholder="Enter DL Number"
                        style={{ textTransform: 'uppercase' }}
                        value={this.state.DLNumber}
                        onChange={this.handelDLNumberChange}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="txtDOB" className="form-label">
                        DOB 
                      </label>
                      <input
                        id="txtDOB"
                        type="text"
                        className="form-control"
                        placeholder="Enter DOB"
                        style={{ textTransform: 'uppercase' }}
                        value={this.state.dobDL}
                        onChange={this.handeldobDBNumberChange}
    
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-6 offset-lg-3">
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ marginLeft: 325 }}
                        onClick={this.handelVerifyClick}
                      >
                        Verify
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div classname="row" style={{ paddingRight: -1, marginRight: 387, paddingTop: 41 }}>
              <section className="card">
                <header className="card-header">
                  <h2 className="card-title" style={{ color: '#0088CC' }}>
                    DL Number Verification 
                  </h2>
                </header>
                <div className="card-body">
                {this.state.apiResponse !== null && (
                <div className="response-container">
                  <h3>{this.state.apiResponse}</h3>
                </div>
              )}
                  {this.state.error !== null && (
                    <div className="error-container">
                      <h3>Response :</h3>
                      <p>{this.state.error}</p>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
        );
      }
    }
    export default DLValidationCheck;
import React, { Component } from "react";
import axios from "axios";
import swal from "sweetalert";

class DLValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DLNumber: "",
      dobDL: "",
      apiResponse: null,
      error: null,
    };
  }

  handleDLNumberChange = (event) => {
    this.setState({ DLNumber: event.target.value });
  };

  handleDOBNumberChange = (event) => {
    this.setState({ dobDL: event.target.value });
  };

  handleVerifyClick = () => {
    const baseApiUrl = process.env.REACT_APP_API_URL;
    const { DLNumber, dobDL } = this.state;
    const apiUrl =`${baseApiUrl}DrivingLicense/GetDrivingLicenseDetails?DLNumber=${DLNumber}&dobDL=${dobDL}`;
    
    axios
      .get(apiUrl)
      .then((response) => {
        if (!response.data) {
          throw new Error("Network response was not ok");
        }
        if (
          response.data.dob ===null
        ) {
       
    
          swal({
            title: "Insufficient Balance",
            text: "Please recharge your account.",
            icon: "error",
          });
        }
        else{
 
      
          this.setState({
            apiResponse: response.data,
            error: null,
          });
        }
 
        
      })
      .catch((error) => {
        this.setState({
          apiResponse: null,
          error: error.message,
        });
      });
  };

  render() {
    return (
      <div className="row">
        <div className="col">
          <section className="card">
            <header className="card-header">
              <h2 className="card-title" style={{ color: "#0088cc" }}>
                Driving License Verification (Paid: 5 credits Charged)
              </h2>
            </header>
            <div className="card-body">
              <div className="form-group row pb-1">
                <div className="col-lg-6">
                  <label htmlFor="txtDLCode" className="form-label">
                    DL Number
                  </label>
                  <input
                    id="txtDLCode"
                    type="text"
                    className="form-control"
                    placeholder="Enter DL Number"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.DLNumber}
                    onChange={this.handleDLNumberChange}
                  />
                </div>
                <div className="col-lg-6">
                  <label htmlFor="txtDOB" className="form-label">
                    DOB
                  </label>
                  <input
                    id="txtDOB"
                    type="text"
                    className="form-control"
                    placeholder="Enter DOB"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.dobDL}
                    onChange={this.handleDOBNumberChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6 offset-lg-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ marginLeft: 325 }}
                    onClick={this.handleVerifyClick}
                  >
                    Verify
                  </button>
                </div>
              </div>
            </div>
          </section>
         
          <div className="row">
          <div className="col">
            {this.state.apiResponse !== null && (
              <div className="card-body">
                <div className="response-container">
                <header className="card-header">
                <h2 className="card-title" style={{ color: '#0088cc' }}>
                  Driving License Verification Response</h2>
                  </header>
                  <table className="table table-responsive-md table-bordered mb-0">
                    <tbody>
                      <tr>
                        <td>DL Number</td>
                        <td>{this.state.apiResponse.dl_number}</td>
                      </tr>
                      <tr>
                        <td>DOB</td>
                      
                        <td>
                        {this.state.apiResponse.dob}
                        </td>
                      </tr>
                     
                      <tr>
                        <td>Date of Issue</td>
                        <td>{this.state.apiResponse.date_of_issue}</td>
                      </tr>
                      <tr>
                        <td>Validity From</td>
                        <td>{this.state.apiResponse.validity_from}</td>
                      </tr>
                      <tr>
                        <td>Validity To</td>
                        <td>{this.state.apiResponse.validity_to}</td>
                      </tr>
                      <tr>
                        <td>badge_issue_date</td>
                        <td>{this.state.apiResponse.badge_issue_date}</td>
                      </tr>
                      <tr>
                        <td>badge_no</td>
                        <td>{this.state.apiResponse.badge_no}</td>
                      </tr>
                      <tr>
                        <td>class_of_vehicle</td>
                        <td>{this.state.apiResponse.class_of_vehicle}</td>
                      </tr>
                      <tr>
                        <td>Father or Husband Name</td>
                        <td>{this.state.apiResponse.father_or_husband_name}</td>
                      </tr>
                      <tr>
                        <td>Complete Address</td>
                        <td>{this.state.apiResponse.complete_address}</td>
                      </tr>
                      <tr>
                        <td>district</td>
                        <td>{this.state.apiResponse.district}</td>
                      </tr>
                      <tr>
                        <td>state</td>
                        <td>{this.state.apiResponse.state}</td>
                      </tr>
                      <tr>
                        <td>city</td>
                        <td>{this.state.apiResponse.city}</td>
                      </tr>
                      <tr>
                        <td>pincode</td>
                        <td>{this.state.apiResponse.pincode}</td>
                      </tr>
                      <tr>
                        <td>country</td>
                        <td>{this.state.apiResponse.country}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      </div>
    );
  }
}



















        {/* </div>
        <div className="row" style={{ paddingRight: -1, marginRight: 387, paddingTop: 41 }}>
          <section className="card">
            <header className="card-header">
              <h2 className="card-title" style={{ color: "#0088CC" }}>
                DL Number Verification
              </h2>
            </header>
            <div className="card-body">
              {this.state.apiResponse !== null ? (
                <div className="response-container">
                  <p>DL Number: {this.state.apiResponse.dl_number}</p>
                  <p>DOB: {this.state.apiResponse.dob}</p>
                  <p>Date of Issue: {this.state.apiResponse.date_of_issue}</p>
                  <p>Validity From: {this.state.apiResponse.validity_from}</p>
                  <p>Validity To: {this.state.apiResponse.validity_to}</p>
                  <p>badge_issue_date: {this.state.apiResponse.badge_issue_date}</p>
                  <p>badge_no: {this.state.apiResponse.badge_no}</p>
                  <p>class_of_vehicle: {this.state.apiResponse.class_of_vehicle}</p>
                  <p>Father or Husband Name: {this.state.apiResponse.father_or_husband_name}</p>
                  <p>Complete Address: {this.state.apiResponse.complete_address}</p>
                  <p>district: {this.state.apiResponse.district}</p>
                  <p>state: {this.state.apiResponse.state}</p>
                  <p>city: {this.state.apiResponse.city}</p>
                  <p>pincode: {this.state.apiResponse.pincode}</p>
                  <p>country: {this.state.apiResponse.country}</p>
                </div>
              ) : (
                <div className="error-container">
                  <h3>Response:</h3>
                  <p>Invalid number</p>
                </div>
              )}

              {this.state.error !== null && (
                <div className="error-container">
                  <h3>Response:</h3>
                  <p>{this.state.error}</p>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    );
  }
} */}

export default DLValidation;

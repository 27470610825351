import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert';

class AadhaarValidation extends Component {
  state = {
    AadhaarNumber: '',
    apiResponse: null,
    error: null,
  };

  handleAadhaarChange = (event) => {
    this.setState({ AadhaarNumber: event.target.value });
  };

  handleVerifyClick = () => {
    const { AadhaarNumber } = this.state;
    const baseApiUrl = process.env.REACT_APP_API_URL;
    const apiUrl =`${baseApiUrl}Aadhaar/GetAadhaarDetails?AadhaarNumber=${AadhaarNumber}`;

    axios
      .get(apiUrl)
      .then((response) => {
        if (!response.data) {
          throw new Error('Network response was not ok');
        }

        // Handle the response from the server here
        console.log(response.data); // Log the data or process it accordingly

        if (
          response.data.aadhaar_result === null
        ) {
       
    
          swal({
            title: "Insufficient Balance",
            text: "Please recharge your account.",
            icon: "error",
          });
        }
        else{
 
      
          this.setState({
            apiResponse: response.data,
            error: null,
          });
        }
 
        
      })
      .catch((error) => {
        this.setState({
          apiResponse: null,
          error: error.message,
        });
      });
  };

  render() {
    return (
      <div className="row">
        <div className="col">
          <section className="card">
            <header className="card-header">
              <h2 className="card-title" style={{ color: '#0088cc' }}>
                Aadhaar Verification (Paid: 5 Credit Charged)
              </h2>
            </header>
            <div className="card-body">
              <div className="form-group row pb-1">
                <div className="col-lg-6">
                  <label htmlFor="txtAadhaar" className="form-label">
                    Aadhaar Number
                  </label>
                  <input
                    id="txtAadhaar"
                    type="text"
                    className="form-control"
                    placeholder="Enter Aadhaar Number"
                    style={{ textTransform: 'uppercase' }}
                    value={this.state.AadhaarNumber}
                    onChange={this.handleAadhaarChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6 offset-lg-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleVerifyClick}
                  >
                    Verify Aadhaar
                  </button>
                </div>
              </div>
            </div>
          </section>
       

        <div className="row">
          <div className="col">
            {this.state.apiResponse !== null && (
              <div className="card-body">
                <div className="response-container">
                <header className="card-header">
                <h2 className="card-title" style={{ color: '#0088cc' }}>
                  Aadhaar Verification Response</h2>
                  </header>
                  <table className="table table-responsive-md table-bordered mb-0">
                    <tbody>
                      <tr>
                        <td>Aadhaar Number</td>
                        <td>{this.state.AadhaarNumber}</td>
                      </tr>
                      <tr>
                        <td>Aadhaar Status</td>
                      
                        <td>
                        <span style={{ color: 'green' }}>{this.state.apiResponse.aadhaar_result}
                        </span></td>
                      </tr>
                     
                      <tr>
                        <td>Aadhaar Age Band</td>
                        <td>{this.state.apiResponse.aadhaar_age_band}</td>
                      </tr>
                      <tr>
                        <td>Aadhaar Gender</td>
                        <td>{this.state.apiResponse.aadhaar_gender}</td>
                      </tr>
                      <tr>
                        <td>Aadhaar Phone</td>
                        <td>{this.state.apiResponse.aadhaar_phone}</td>
                      </tr>
                      <tr>
                        <td>Aadhaar State</td>
                        <td>{this.state.apiResponse.aadhaar_state}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default AadhaarValidation;

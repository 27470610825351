import React, { Component } from "react";
import axios from "axios";
import swal from "sweetalert";
import { data } from "jquery";

class AadhaarPanLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
        AadhaarNumber: "",
      PanNumber: "",
      apiResponse: null,
   
    };
  }

  handleAadhaarNumberChange = (event) => {
    this.setState({ AadhaarNumber: event.target.value });
  };

  handlePanNumberChange = (event) => {
    this.setState({ PanNumber: event.target.value });
  };


  handleSubmit = () => {
     // Prevent the default form submission behavior
     const baseApiUrl = process.env.REACT_APP_API_URL;
    const { AadhaarNumber, PanNumber } = this.state;
    const apiUrl = `${baseApiUrl}AadhaarPANLink/GetAadhaarPanLinkValidation?AadhaarNumber=${AadhaarNumber}&PanNumber=${PanNumber}`;

    axios
    .get(apiUrl)
    .then(response => {
        console.log(response);
      if (!response.data) {
        throw new Error('Network response was not ok');
      }

      if (response.data && (response.data.code === null || response.data.message === null)) {
       
    
         swal({
           title: "Insufficient Balance",
           text: "Please recharge your account.",
           icon: "error",
         });
       }
       else{

     
         this.setState({
           apiResponse: response.data,
           error: null,
         });
       }

       
     })
    .catch(error => {
      this.setState({
        apiResponse: null,
        error: error.message,
      });
    });
};

  render() {
    return (
      <div className="row">
        <div className="col">
          <section className="card">
            <header className="card-header">
              <h2 className="card-title" style={{ color: "#0088cc" }}>
                AadhaarPANLink Validation (paid: 5 credit Charged)
              </h2>
            </header>
            <div className="card-body">
           
                <div className="form-group row pb-1">
                  <div className="col-lg-6">
                    <label htmlFor="txtAadhaarNumber" className="form-label">
                      Aadhaar Number
                    </label>
                    <input
                      id="txtAadhaarNumber"
                      type="text"
                      className="form-control"
                      placeholder="Enter Aadhaar Number"
                      style={{ textTransform: "uppercase" }}
                      value={this.state.AadhaarNumber}
                      onChange={this.handleAadhaarNumberChange}
                    />
                  </div>
                  <div className="col-lg-6">
                    <label htmlFor="txtPanNumber" className="form-label">
                      PAN Number
                    </label>
                    <input
                      id="txtPanNumber"
                      type="text"
                      className="form-control"
                      placeholder="Enter PAN Number"
                      style={{ textTransform: "uppercase" }}
                      value={this.state.PanNumber}
                      onChange={this.handlePanNumberChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                <div className="col-lg-6 offset-lg-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleSubmit}
                  >
                    Verify
                  </button>
                </div>
              </div>
            </div>
          </section>
 

<div className="row">
  <div className="col">
    {this.state.apiResponse!==null&&(
      <div className="card-body">
        <div className="response-container">
          <header className="card-header">
            <h2 className="card-tittle" style={{color:'#0088cc'}}>
              Aadhaar PAN Link Verification Response
            </h2>
          </header>
          <table className="table table-responsive-md table-bordered mb-0">
                    <tbody>
                      <tr>
                        <td>Message</td>
                        <td>{this.state.apiResponse.message}</td>
                      </tr>
                      <tr>
                        <td>Code</td>
                        <td>{this.state.apiResponse.code}</td>
                      </tr>

                      </tbody>
                  </table>
                  </div>
              </div>
            )}
          </div>
        </div>
      </div>
      </div>
    );
  }
}
export default AadhaarPanLink;

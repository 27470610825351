import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PageHeader extends Component {
    render() {
        return (
            <header className="page-header">
                <h2 runat="server" id="PageHeader">
                    {this.props.PageHeaderName}
                </h2>
                <div className="right-wrapper text-end">
                    <ol className="breadcrumbs">
                        <li>
                            <Link to="../dashboard/insta-dashboard.aspx">
                                <i className="bx bx-home-alt" />
                            </Link>
                        </li>
                        <li>
                            <Link to="#">
                                <span id="ParentLink">Dashboard</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="#">
                                <span id="CurrentLink">InstaKYC</span>
                            </Link>
                        </li>
                    </ol>

                    <a className="sidebar-right-toggle" data-open="" />
                </div>
            </header>
        );
    }
}

export default PageHeader;

import React, { Component } from "react";
import axios from "axios";
import swal from "sweetalert";

class RCValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RegNO: "",
      apiResponse: null,
      error: null,
    };
  }

  handeLRegNumberChange = (event) => {
    this.setState({ RegNO: event.target.value });
  };

  handelVerifyClick = () => {
    const { RegNO } = this.state;
    const baseApiUrl = process.env.REACT_APP_API_URL;
    const apiUrl =`${baseApiUrl}RCValidation/GetRCValidation?RegNO=${RegNO}`; // You should specify the actual URL here.
    axios
      .get(apiUrl)
      .then((response) => {
        if (!response.data) {
          throw new Error("Network response was not ok");
        }
        if (
          response.data.class === null
        ) {
       
    
          swal({
            title: "Insufficient Balance",
            text: "Please recharge your account.",
            icon: "error",
          });
        }
        else{
 
      
          this.setState({
            apiResponse: response.data,
            error: null,
          });
        }
 
        
      })
      .catch((error) => {
        this.setState({
          apiResponse: undefined,
          error: error.message,
        });
      });
  };

  render() {
    return (
      <div className="row">
        <div className="col">
          <section className="card">
            <header className="card-header">
              <h2 className="card-title" style={{ color: "#0088cc" }}>
                RC Verification (Paid: 5 Credit Charged)
              </h2>
            </header>
            <div className="card-body">
              <div className="form-group row pb-1">
                <div className="col-lg-6">
                  <label htmlFor="txtRegNo" className="form-label">
                    Registration Number
                  </label>
                  <input
                    id="txtRegNo"
                    className="form-control"
                    placeholder="Enter Registration Number"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.RegNO}
                    onChange={this.handeLRegNumberChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6 offset-lg-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handelVerifyClick}
                  >
                    Verify
                  </button>
                </div>
              </div>
            </div>
          </section>



     
          <div className="row">
          <div className="col">
            {this.state.apiResponse !== null && (
              <div className="card-body">
                <div className="response-container">
                <header className="card-header">
                <h2 className="card-title" style={{ color: '#0088cc' }}>
                  RC Verification Response</h2>
                  </header>
                  <table className="table table-responsive-md table-bordered mb-0">
  <tbody>
    <tr>
      <td>Registration Number</td>
      <td>{this.state.apiResponse.reg_no}</td>
    </tr>
    <tr>
      <td>Vehicle Class</td>
      <td>{this.state.apiResponse.class}</td>
    </tr>
    <tr>
      <td>Chassis</td>
      <td>{this.state.apiResponse.chassis}</td>
    </tr>
    <tr>
      <td>Engine</td>
      <td>{this.state.apiResponse.engine}</td>
    </tr>
    <tr>
      <td>Vehicle Manufacturer Name</td>
      <td>{this.state.apiResponse.vehicle_manufacturer_name}</td>
    </tr>
    <tr>
      <td>Model</td>
      <td>{this.state.apiResponse.model}</td>
    </tr>
    <tr>
      <td>Vehicle Colour</td>
      <td>{this.state.apiResponse.vehicle_colour}</td>
    </tr>
    <tr>
      <td>Type</td>
      <td>{this.state.apiResponse.type}</td>
    </tr>
    <tr>
      <td>Body Type</td>
      <td>{this.state.apiResponse.body_type}</td>
    </tr>
    <tr>
      <td>Owner Count</td>
      <td>{this.state.apiResponse.owner_count}</td>
    </tr>
    <tr>
      <td>Owner Name</td>
      <td>{this.state.apiResponse.owner_name}</td>
    </tr>
    <tr>
      <td>Owner Father Name</td>
      <td>{this.state.apiResponse.owner_father_name}</td>
    </tr>
    <tr>
      <td>Mobile Number</td>
      <td>{this.state.apiResponse.mobile_number}</td>
    </tr>
    <tr>
      <td>Status</td>
      <td>{this.state.apiResponse.status}</td>
    </tr>
    <tr>
      <td>Status As On</td>
      <td>{this.state.apiResponse.status_as_on}</td>
    </tr>
    <tr>
      <td>Reg Authority</td>
      <td>{this.state.apiResponse.reg_authority}</td>
    </tr>
    <tr>
      <td>Reg Date</td>
      <td>{this.state.apiResponse.reg_date}</td>
    </tr>
    <tr>
      <td>Vehicle Manufacturing Month/Year</td>
      <td>{this.state.apiResponse.vehicle_manufacturing_month_year}</td>
    </tr>
    <tr>
      <td>RC Expiry Date</td>
      <td>{this.state.apiResponse.rc_expiry_date}</td>
    </tr>
    <tr>
      <td>Vehicle Tax Upto</td>
      <td>{this.state.apiResponse.vehicle_tax_upto}</td>
    </tr>
    <tr>
      <td>Vehicle Insurance Company Name</td>
      <td>{this.state.apiResponse.vehicle_insurance_company_name}</td>
    </tr>
    <tr>
      <td>Vehicle Insurance Upto</td>
      <td>{this.state.apiResponse.vehicle_insurance_upto}</td>
    </tr>
    <tr>
      <td>Vehicle Insurance Policy Number</td>
      <td>{this.state.apiResponse.vehicle_insurance_policy_number}</td>
    </tr>
    <tr>
      <td>RC Financer</td>
      <td>{this.state.apiResponse.rc_financer}</td>
    </tr>
    <tr>
      <td>Present Address</td>
      <td>{this.state.apiResponse.present_address}</td>
    </tr>
    <tr>
      <td>Permanent Address</td>
      <td>{this.state.apiResponse.permanent_address}</td>
    </tr>
    <tr>
      <td>Vehicle Cubic Capacity</td>
      <td>{this.state.apiResponse.vehicle_cubic_capacity}</td>
    </tr>
    <tr>
      <td>Gross Vehicle Weight</td>
      <td>{this.state.apiResponse.gross_vehicle_weight}</td>
    </tr>
    <tr>
      <td>Unladen Weight</td>
      <td>{this.state.apiResponse.unladen_weight}</td>
    </tr>
    <tr>
      <td>Vehicle Category</td>
      <td>{this.state.apiResponse.vehicle_category}</td>
    </tr>
    <tr>
      <td>RC Standard Capacity</td>
      <td>{this.state.apiResponse.rc_standard_cap}</td>
    </tr>
    <tr>
      <td>Vehicle Cylinders No.</td>
      <td>{this.state.apiResponse.vehicle_cylinders_no}</td>
    </tr>
    <tr>
      <td>Vehicle Seat Capacity</td>
      <td>{this.state.apiResponse.vehicle_seat_capacity}</td>
    </tr>
    <tr>
      <td>Vehicle Sleeper Capacity</td>
      <td>{this.state.apiResponse.vehicle_sleeper_capacity}</td>
    </tr>
    <tr>
      <td>Vehicle Standing Capacity</td>
      <td>{this.state.apiResponse.vehicle_standing_capacity}</td>
    </tr>
    <tr>
      <td>Wheelbase</td>
      <td>{this.state.apiResponse.wheelbase}</td>
    </tr>
    <tr>
      <td>Vehicle Number</td>
      <td>{this.state.apiResponse.vehicle_number}</td>
    </tr>
    <tr>
      <td>PUCC Number</td>
      <td>{this.state.apiResponse.pucc_number}</td>
    </tr>
    <tr>
      <td>PUCC Upto</td>
      <td>{this.state.apiResponse.pucc_upto}</td>
    </tr>
    <tr>
      <td>Blacklist Status</td>
      <td>{this.state.apiResponse.blacklist_status}</td>
    </tr>
    <tr>
      <td>Permit Issue Date</td>
      <td>{this.state.apiResponse.permit_issue_date}</td>
    </tr>
    <tr>
      <td>Permit Number</td>
      <td>{this.state.apiResponse.permit_number}</td>
    </tr>
    <tr>
      <td>Permit Type</td>
      <td>{this.state.apiResponse.permit_type}</td>
    </tr>
    <tr>
      <td>Permit Valid From</td>
      <td>{this.state.apiResponse.permit_valid_from}</td>
    </tr>
    <tr>
      <td>Permit Valid Upto</td>
      <td>{this.state.apiResponse.permit_valid_upto}</td>
    </tr>
    <tr>
      <td>Non-Use Status</td>
      <td>{this.state.apiResponse.non_use_status}</td>
    </tr>
    <tr>
      <td>Non-Use From</td>
      <td>{this.state.apiResponse.non_use_from}</td>
    </tr>
    <tr>
      <td>Non-Use To</td>
      <td>{this.state.apiResponse.non_use_to}</td>
    </tr>
    <tr>
      <td>National Permit Number</td>
      <td>{this.state.apiResponse.national_permit_number}</td>
    </tr>
    <tr>
      <td>National Permit Upto</td>
      <td>{this.state.apiResponse.national_permit_upto}</td>
    </tr>
    <tr>
      <td>National Permit Issued By</td>
      <td>{this.state.apiResponse.national_permit_issued_by}</td>
    </tr>
    <tr>
      <td>NOC Details</td>
      <td>{this.state.apiResponse.noc_details}</td>
    </tr>
  </tbody>
</table>

                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default RCValidation;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery'; // Import jQuery

class Sidebar extends Component {
  componentDidMount() {
    // Initialize the dropdown behavior when the component mounts
    this.initializeDropdown();
  }

  componentWillUnmount() {
    // Clean up and remove the event listener when the component unmounts
    this.destroyDropdown();
  }

  initializeDropdown() {
    // Use jQuery to add the necessary event handlers for the dropdown
    $('.nav-parent > a').on('click', function (e) {
      e.preventDefault();
      const $parent = $(this).parent();
      $parent.toggleClass('open');
      $parent.children('ul').slideToggle(200);
    });
  }

  destroyDropdown() {
    // Remove event handlers when the component unmounts to avoid memory leaks
    $('.nav-parent > a').off('click');
  }

  render() {
    return (
      <>
        {/* start: sidebar */}
        <aside id="sidebar-left" className="sidebar-left">
          <div className="sidebar-header">
            <div className="sidebar-title">Navigation</div>
            <div
              className="sidebar-toggle d-none d-md-block"
              data-toggle-class="sidebar-left-collapsed"
              data-target="html"
              data-fire-event="sidebar-left-toggle"
            >
              <i className="fas fa-bars" aria-label="Toggle sidebar"></i>
            </div>
          </div>

          <div className="nano">
            <div className="nano-content">
              <nav id="menu" className="nav-main" role="navigation">
                <ul className="nav nav-main">
                  <li>
                    <Link className="nav-link" to="/dashboard">
                      <i className="bx bx-home-alt" aria-hidden="true"></i>
                      <span>Dashboard</span>
                    </Link>
                  </li>
                  <li className={`nav-parent`}>
  <Link className="nav-link" to="#">
    <i className="bx bx-file" aria-hidden="true"></i>
    <span>Verify KYC</span>
  </Link>
  <ul className="nav nav-children">
    <li className={`nav-parent`}>
    <Link className="nav-link" to="#">
    <i className="bx bxs-business" aria-hidden="true"></i> {/* Add the user icon here */}
    <span>
      <box-icon type='solid' name='business'></box-icon> Business KYC
    </span>
  </Link>
      <ul className="nav nav-children">
        <li>
          <Link className="nav-link" to="/EPFVerification">
            EPF Validation
          </Link>
        </li>
        <li>
          <Link className="nav-link" to="/PANVerification">
            PAN Validation
          </Link>
        </li>
        <li>
          <Link className="nav-link" to="/GSTINValidation">
            GSTIN Validation
          </Link>
        </li>
      </ul>
    </li>

    <li className={`nav-parent`}>
      <Link className="nav-link" to="#">
        <i className="bx bx-user" aria-hidden="true"></i>
        <span>Individual KYC</span>
      </Link>
      <ul className="nav nav-children">
        <li>
          {/* Add individual KYC options here */}
          <Link className="nav-link" to="/AadhaarValidationCheck">
            Aadhaar Validation
          </Link>
        </li>
        <li>
          <Link className="nav-link" to="/PANVerification">
            PAN Validation
          </Link>
        </li>
        <li>
          <Link className="nav-link" to="/RCValidationCheck">
            RegNo Validation
          </Link>
        </li>
        <li>
          <Link className="nav-link" to="/AadhaarPANLinkCheck">
            AadhaarPanLink Validation
          </Link>
        </li>
        <li>
          <Link className="nav-link" to="/DLValidationCheck">
            DL Number Validation
          </Link>
        </li>
      </ul>
    </li>
  </ul>
</li>



                  <li className={`nav-parent`}>
                    <Link className="nav-link" to="#">
                      <i className="bx bx-file" aria-hidden="true"></i>
                      <span>InstaKYC Detailed Validtion</span>
                    </Link>
                    <ul className="nav nav-children">
                      <li>
                        <Link className="nav-link" to="/AadhaarValidation">
                          Aadhaar Detailed Verification
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/RegNoVlaidation">
                          RC Detailed Verification
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/AadhaarPANLinkCheck">
                          AadhaarPanLink Verification
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/DLValidation">
                          Driving Liecense Verification
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/UANVerification">
                          UAN Verification
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className={`nav-parent`}>
                    <Link className="nav-link" to="#">
                    <i className="fa-regular fa-wallet" style={{color: "#abb4be",}} aria-hidden="true"></i>
                      <span>Wallet</span>
                    </Link>
                    <ul className="nav nav-children">
                      <li>
                        <Link className="nav-link" to="/RazerPayment">
                          Wallet Recharge
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" to="/wallettransaction">
                         Wallet Transactions
                        </Link>
                      </li>
                    </ul>
                  </li>


                </ul>
              </nav>
            </div>
          </div>
        </aside>
        {/* end: sidebar */}
      </>
    );
  }
}

export default Sidebar;

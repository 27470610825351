import React, { Component } from "react";
import axios from "axios";
import swal from 'sweetalert';

class GSTValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
        GSTIN: "",
        TradeName: "",
        apiResponse: null, // Initially set to null
        error: null,
      };
      
  }

  handelGSTNumberChange = (event) => {
    this.setState({ GSTIN: event.target.value });
  };

  handelTradeName = (event) => {
    this.setState({ TradeName: event.target.value });
  };

  handelVerifyClick = () => {
    const baseApiUrl = process.env.REACT_APP_API_URL;
    const { GSTIN, TradeName } = this.state;
    const apiUrl =`${baseApiUrl}Home/GSTKYCbyGSTIN?GSTIN=${GSTIN}&TradeName=${TradeName}`;
    axios
      .get(apiUrl)
      .then((response) => {
        if (!response.data) {
          throw new Error("Network response was not ok");
        }
        if(response.data.message=='Insufficient balance to pay credits.')
        {
 
      
           swal({
             title: "Insufficient Balance",
             text: "Please recharge your account.",
             icon: "error",
           });
         }
         else{
 
       
           this.setState({
             apiResponse: response.data,
             error: null,
           });
         }
       })
      .catch((error) => {
        this.setState({
          apiResponse: undefined,
          error: error.message,
        });
      });
  };

  render() {
    return (
      <div className="row">
        <div className="col">
          <section className="card">
            <header className="card-header">
              <h2 className="card-title" style={{ color: "#0088CC" }}>
                GSTIN Verification (Paid: 5 Credit Charged)
              </h2>
            </header>
            <div className="card-body">
              <div className="form-group row pb-1">
                <div className="col-lg-6">
                  <label htmlFor="txtGSTIN" className="form-label">
                    GSTIN Number
                  </label>
                  <input
                    id="txtGSTIN"
                    className="form-control"
                    placeholder="Enter GSTIN"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.GSTIN}
                    onChange={this.handelGSTNumberChange}
                  />
                </div>

                <div className="col-lg-6">
                  <label htmlFor="txtTradeName" className="form-label">
                    Trade Name
                  </label>
                  <input
                    id="txtTradeName"
                    type="text"
                    className="form-control"
                    placeholder="Enter TradeName"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.TradeName}
                    onChange={this.handelTradeName}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6 offset-lg-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handelVerifyClick}
                  >
                    Verify
                  </button>
                </div>
              </div>
              </div>
          </section>
              <div className="col">
  <section className="card">
    <header className="card-header">
      <h2 className="card-title" style={{ color: "#0088cc" }}>
        Result
      </h2>
    </header>
    <div className="card-body">
      {this.state.apiResponse?.message && (
        <div className="response-container">
          <p>{this.state.apiResponse.message}</p>
        </div>
      )}
    </div>
  </section>
</div>










          
        </div>

      
      </div>
    );
  }
}

export default GSTValidation;

import React, { Component } from 'react';

class InnerWrapper extends Component
{
    render()
    {
        return(<>
        <div className="inner-wrapper" style={{paddingTop:"38px"}}>
            {this.props.Sidebar}
            {this.props.Main}
          </div>
        </>)
    }
}

export default InnerWrapper;
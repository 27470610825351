import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import '../wallet.css';
import axios, { Axios } from "axios";
import swal from "sweetalert";

function RazerPayment() {
  const [amount, setAmount] = useState("");

  const handleDefaultAmount = (defaultAmount) => {
    setAmount(defaultAmount);
    handlePayment(defaultAmount);
  };


  const cardStyles = {
    fontFamily: 'Montserrat, sans-serif',
    color: '#8d97ad',
    
    

    fontWeight: 300,
    boxShadow: '0px 0px 30px rgba(115, 128, 157, 0.1)',
    transition: '0.1s',
  };

  const hoverStyles = {
    transform: 'scale(1.05)',
    WebkitTransform: 'scale(1.05)',
    WebkitFontSmoothing: 'antialiased',
  };

  const btnSuccessStyles = {
    background: 'linear-gradient(to right, #2cdd9b 0%, #1dc8cc 100%)',
  };

  const handleSubmit = () => {
    if (amount === "") {
      alert("Please enter an amount");
      return;
    }
    handlePayment(amount);
  };
  const handlePayment = (selectedAmount) => {
    const options = {
      key: "rzp_test_raqJyce17nN466",
      key_secret: "VMk3MKCxYB86GcaFUumJzBkq",
      amount: selectedAmount * 100, // Convert the amount to the smallest currency unit (e.g., cents)
      currency: "INR",
      name: "InstaKYC",
      description: `Payments for ${selectedAmount} Credits`,
      handler: function (response) {
        console.log(response); // Log the entire response object
      
       
        const PaymentID = response.razorpay_payment_id;
        const Amount=(amount);
        sendPaymentIdToBackend(PaymentID,Amount);
      },
      prefill: {
        name: "instakyc",
        email: "ktkalyan313@gmail.com",
        contact: "8904300965",
      },
      notes: {
        address: "instaoffice",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const razorpay = new window.Razorpay(options);
    razorpay.open();
  };
  const sendPaymentIdToBackend = (PaymentID,Amount) => {
    const baseApiUrl = process.env.REACT_APP_API_URL;
    const apiUrl = `${baseApiUrl}Home/WalletRecharge?PaymentID=${PaymentID}&amount=${Amount}`;
    axios
    .get(apiUrl)
    .then((response) => {
      if (!response.data) {
        throw new Error("Network response was not ok");
      } else if (response.data === null) {
       
        swal({
          title: "Error",
          text: "Network response was null.",
          icon: "error"
        });
      } else {
       
        swal({
          title: "Recharge Successfully Done",
          text: "Successfully Done.",
          icon: "success"
        });
      }
    })
    .catch((error) => {
    
      console.error(error);
      // Display an error message
      swal({
        title: "Error",
        text: "Network response was not ok",
        icon: "error"
      });
    });



  };




  return (    
    <div className="wallet-recharge-container">

     
      
      <div className="row mt-5">
    
      <div className="col-lg-3 col-md-6">
      <div className="card text-center card-shadow on-hover border-0 mb-4" style={cardStyles}>
      <div className="card-body font-14" style={{paddingTop:"7rem",paddingRight:"2rem",paddingBottom:"7rem",paddingLeft:"2rem"}}>
          <h5 className="mt-3 font-weight-medium" style={{color:'black',fontSize:"19px",fontFamily:"sans-serif",paddingTop: "7px"}}>Paid 3000 Credits</h5>
          <h6 className="subtitle font-weight-normal"></h6>
          <div className="pricing my-3">
          <sup>RS</sup>
            <span className="monthly display-5" style={{ fontSize: '31px', lineHeight: '31px' }}>
             3000
            </span>
            <span className="d-block"><span className="font-weight-medium">Get Instant 3000 Credits by paying Rs.3000</span></span>
          </div>
          <div className="bottom-btn">
            <a
              className="btn btn-md text-white btn-block"
              style={{ ...btnSuccessStyles, padding: '7px 7px', fontSize: '16px' }}
              href="#f1"
              onClick={() => handleDefaultAmount(3000)} 
            >
              <span>Pay Credits</span>
            </a>
          </div>
        </div>
      </div>
    </div>


    <div className="col-lg-3 col-md-6">
      <div className="card text-center card-shadow on-hover border-0 mb-4" style={cardStyles}>
      <div className="card-body font-14" style={{paddingTop:"7rem",paddingRight:"2rem",paddingBottom:"7rem",paddingLeft:"2rem"}}>
          <h5 className="mt-3 font-weight-medium" style={{color:'black',fontSize:"19px",fontFamily:"sans-serif",paddingTop: "7px"}}>Paid 4000 Credits</h5>
          <h6 className="subtitle font-weight-normal"></h6>
          <div className="pricing my-3">
          <sup>RS</sup>
            <span className="monthly display-5" style={{ fontSize: '31px', lineHeight: '31px' }}>
            4000
            </span>
            <span className="d-block"><span className="font-weight-medium">Get Instant 4000 Credits by paying Rs.4000</span></span>
          </div>
          <div className="bottom-btn">
            <a
              className="btn btn-md text-white btn-block"
              style={{ ...btnSuccessStyles, padding: '7px 7px', fontSize: '16px' }}
              href="#f1"
              onClick={() => handleDefaultAmount(4000)} 
            >
              <span>Pay Credits</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div className="col-lg-3 col-md-6">
      <div className="card text-center card-shadow on-hover border-0 mb-4" style={cardStyles}>
      <div className="card-body font-14" style={{paddingTop:"7rem",paddingRight:"2rem",paddingBottom:"7rem",paddingLeft:"2rem"}}>
          <h5 className="mt-3 font-weight-medium" style={{color:'black',fontSize:"19px",fontFamily:"sans-serif",paddingTop: "7px"}}>Paid 5000 Credits</h5>
          <h6 className="subtitle font-weight-normal"></h6>
          <div className="pricing my-3">
          <sup>RS</sup>
            <span className="monthly display-5" style={{ fontSize: '31px', lineHeight: '31px' }}>
            5000
            </span>
            <span className="d-block"><span className="font-weight-medium">Get Instant 5000 Credits by paying Rs.5000</span></span>
          </div>
          <div className="bottom-btn">
            <a
              className="btn btn-md text-white btn-block"
              style={{ ...btnSuccessStyles, padding: '7px 7px', fontSize: '16px' }}
              href="#f1"
              onClick={() => handleDefaultAmount(5000)} 
            >
              <span>Pay Credits</span>
            </a>
          </div>
        </div>
      </div>
    </div>



    <div className="col-lg-3 col-md-6">
      <div className="card text-center card-shadow on-hover border-0 mb-4" style={cardStyles}>
      <div className="card-body font-14" style={{ padding: '6rem 2rem' }}>
              <h5 className="mt-3 font-weight-medium" style={{ color: 'black', fontSize: '19px', fontFamily: 'sans-serif', paddingTop: '9px' }}>Custom Amount</h5>
              <h6 className="subtitle font-weight-normal">Paid Credit: {amount}</h6>
              <div className="input-container" style={{ marginBottom: '17px', marginTop: '10px' }}>
                <input
                  type="number"
                  placeholder="Enter Custom Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <span className="d-block"><span className="font-weight-medium">Get Instant Credits by paying Rs.{amount}</span></span>
              <div className="bottom-btn" style={{marginTop:"14px"}}>
  <a
    className="btn btn-md text-white btn-block"
    style={{
      ...btnSuccessStyles,
      padding: '7px 7px',
      fontSize: '16px',
      background: 'linear-gradient(to right, #ff4d7e 0%, #ff6a5b 100%)',
    }}
    href="#f1"
    onClick={() => handleSubmit()}
  >
    <span>Pay Credits</span>
  </a>
</div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
}

export default RazerPayment;

import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class WalletComponent extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      startDate: null,
      endDate: null,
     
      amount: '',
      tableData: [], 
      columns: [
      
        { dataField: 'openingBalance', text: 'Opening Balance' },
        { dataField: 'transactionAmount', text: 'Transaction Amount', sort: true },
        { dataField: 'closingBalance', text: 'Closing Balance', sort: true },
        { dataField: 'remark', text: 'Description', sort: true },
        { dataField: 'updatedOn', text: 'Date', sort: true },
      ],
      selectedUser: null,  
      selectedUserName: '',
      subUsers: [],
      WalletTotalAmount:0,
      totalCredit: 0,
      totalDebit: 0,
    };
  }

  componentDidMount() {
    this.fetchDataForTable();
  }
  handleStartDateChange = date => {
    this.setState({ startDate: date });
  };

  handleEndDateChange = date => {
    this.setState({ endDate: date });
  };
  handleUserChange = (event) => {
    debugger;
    const selectedUserID = event.target.value === '' ? null : event.target.value;
    this.setState({ selectedUser: selectedUserID });
  };
  formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toDateString();

  };
  
  handleFilter = () => {
    debugger;
    let instaUserID = this.state.selectedUser ?? 1;
    const startDate = this.formatDate(this.state.startDate);
    const endDate = this.formatDate(this.state.endDate);
    const baseApiUrl = process.env.REACT_APP_API_URL;
    let apiUrl2 =`${baseApiUrl}Home/GetFilterWalletTranscations?startDate=${startDate}&endDate=${endDate}`;

  if (instaUserID) {
    apiUrl2 += `&instaUserID=${instaUserID}`;
  }
  axios
  .get(apiUrl2)
  .then((response) => {
   

    console.log(this.state.tableData);

    this.setState({
      tableData: response.data[0],
      totalCredit: response.data[1],
      totalDebit: response.data[2],
      WalletTotalAmount: response.data[3],
      subUsers: response.data[4],
    });
    console.log('Updated Table Data:', this.state.tableData);
    console.log('Updated Total Credit:', this.state.totalCredit);
    console.log('Updated Total Debit:', this.state.totalDebit);
    console.log('Updated Wallet Total Amount:', this.state.WalletTotalAmount);
    console.log('Updated Sub Users:', this.state.subUsers);
  })
  .catch((error) => {
    console.error(error);
  });

// Logging the values for demonstration purposes:
console.log('Selected User:', instaUserID);
console.log('Start Date:', startDate);
console.log('End Date:', endDate);
};
  fetchDataForTable() {
    // Make an API call to fetch table data
    const InstaUserID = "ALL";
    const baseApiUrl = process.env.REACT_APP_API_URL;
    const apiUrl1 =`${baseApiUrl}Home/GetWalletTranscations?InstaUserID=${InstaUserID}`
    axios
    .get(apiUrl1)
    .then((response) => {
      console.log('API Response:', response.data[4]);
     
      this.setState({ tableData: response.data[0], totalCredit: response.data[1], totalDebit: response.data[2],WalletTotalAmount:response.data[3] ,subUsers:response.data[4]});
    })
    .catch((error) => {
      console.error(error);
    });
}

  handleSubmit = () => {
    if (this.state.amount === '') {
      alert('Please enter an amount');
      return;
    }
    this.handlePayment(this.state.amount);
  };

  handlePayment = (selectedAmount) => {
    const options = {
      key: 'rzp_test_raqJyce17nN466',
      key_secret: 'VMk3MKCxYB86GcaFUumJzBkq',
      amount: selectedAmount * 100, // Convert the amount to the smallest currency unit (e.g., cents)
      currency: 'INR',
      name: 'InstaKYC',
      description: `Payments for ${selectedAmount} Credits`,
      handler: (response) => {
     

        const PaymentID = response.razorpay_payment_id;
        const Amount = this.state.amount;
        this.sendPaymentIdToBackend(PaymentID, Amount);
      },
      prefill: {
        name: 'instakyc',
        email: 'ktkalyan313@gmail.com',
        contact: '8904300965',
      },
      notes: {
        address: 'instaoffice',
      },
      theme: {
        color: '#3399cc',
      },
    };

    const razorpay = new window.Razorpay(options);
    razorpay.open();
  };

  sendPaymentIdToBackend = (PaymentID, Amount) => {
    const baseApiUrl = process.env.REACT_APP_API_URL;
    const apiUrl = `${baseApiUrl}Home/WalletRecharge?PaymentID=${PaymentID}&amount=${Amount}`;
    axios
      .get(apiUrl)
      .then((response) => {
        if (!response.data) {
          throw new Error('Network response was not ok');
        } else if (response.data === null) {
          swal({
            title: 'Error',
            text: 'Network response was null.',
            icon: 'error',
          });
        } else {
          swal({
            title: 'Recharge Successfully Done',
            text: 'Successfully Done.',
            icon: 'success',
          });
        }
      })
      .catch((error) => {
        console.error(error);
        // Display an error message
        swal({
          title: 'Error',
          text: 'Network response was not ok',
          icon: 'error',
        });
      });
  };
  render(){

   

  return (
    <div className="container-fluid row justify-content-between" id="wallet" style={{ paddingLeft: 0, paddingRight: 0 }}>
      <div className="col-md-6" style={{ borderBottom: '1px solid #e7eaec', backgroundColor: '#fff', paddingTop: '1.2rem', paddingBottom: '1.2rem', marginRight: '15px' }}>
        <h2 className="no-margins" style={{ marginTop: '5px', marginRight: '153px', marginBottom: '26px', textAlign: 'center', fontSize: '28px', fontWeight: '700' }}>
          <span>Rs. {this.state.WalletTotalAmount}</span>
          
        </h2>
        <p style={{ marginTop: '-70px', textAlign: 'right', marginBottom: '9px' }}>Your Wallet Balance</p>
        <p style={{ marginTop: '8px', textAlign: 'right' }}>Valid Till: Not Applicable</p>
      </div>





   
  <div className="col" style={{ borderBottom: '1px solid #e7eaec', backgroundColor: '#fff', paddingTop: '2.2rem', paddingBottom: '1.2rem',  textAlign: 'center' }}>
    <div className="input-container" style={{ marginBottom: '17px', marginTop: '10px' }}>
      <input
        type="number"
        placeholder="Enter Amount"
        value={this.state.amount}
        onChange={(e) => this.setState({ amount: e.target.value })}
        style={{ width: '145px', fontSize: '14px', height: '29px', borderColor: '#ecedf0' }}
      />
    </div>
  </div>
  <div className="col" style={{ borderBottom: '1px solid #e7eaec', backgroundColor: '#fff', paddingTop: '2.2rem', paddingBottom: '1.2rem', textAlign: 'center' }}>
    <div className="bottom-btn">
      <a
        className="btn btn-md text-white btn-block"
        style={{
          padding: '7px 7px',
          fontSize: '16px',
          background: 'linear-gradient(45deg, #18914e 0%, #60d72f 100%)',
          color: '#fff',
        }}
        href="#f1"
        onClick={() => this.handleSubmit()}
      >
        <span>
          <i className="fa fa-wallet" style={{ marginRight: '5px' }}></i>Load Money to Your Wallet
        </span>
      </a>
    </div>
  </div>


<br/>


<div className="row" style={{marginBlockStart:"11px",marginBlockEnd:"-11px"}}>
  <div className="ii-content__cbox__title col-lg-12 u-margin-0 u-pd-y--12 u-pd-x--8">
    <div className="row d-flex justify-content-center">
      <div className="col-lg-3 d-flex align-items-center">
        <p style={{ margin: 0 }}>
          <strong style={{ fontSize: '17px', fontWeight: 'bold' }}>
            Your Transactions
          </strong>
        </p>
      </div>
      <div className="col-lg-3 d-flex align-items-center">
        <p style={{ margin: 0 }}>
          <strong style={{ fontSize: '17px', fontWeight: 'bold' }}>
            Total Credit : <span id="totalCredit" style={{ color: 'limegreen' }}>{this.state.totalCredit}</span>
          </strong>
        </p>
      </div>
      <div className="col-lg-3 d-flex align-items-center">
        <p style={{ margin: 0 }}>
          <strong style={{ fontSize: '17px', fontWeight: 'bold' }}>
            Total Debit : <span id="totalDebit" style={{ color: 'red' }}>{this.state.totalDebit}</span>
          </strong>
        </p>
      </div>
    </div>
  </div>
</div>








<div className="ii-content__cbox__title"style={{overflow:'hidden'}}>
  <div className="col-lg-12 u-margin-0 u-pd-y--12" style={{ paddingBottom: '4.2rem', background: 'white',marginTop:"1.2rem" }}>
    <div  className="col-lg-2 u-pd-y--4"style={{float:'left',marginLeft:"6%"}}>
      <h4>All Transactions</h4>
    </div>
    <div className="col-lg-2 u-pd-y--4" style={{float:'left',marginTop:"1.5%"}} >
      <select
        id="ddlInstaUsers"
        style={{ width: '100%', maxWidth: '7.4375rem', height: '1.875rem'}}
        value={this.state.selectedUser}
        onChange={this.handleUserChange}
      >
        <option value="">Sub Users</option>
        {this.state.subUsers.map((user) => (
          <option key={user.instaUserID} value={user.instaUserID}>
            {user.userName}
          </option>
        ))}
      </select>
    </div>
    <div className="col-lg-2 u-pd-y--4" style={{float:'left',marginTop:"1.5%"}}>
      <DatePicker
        id="StartDate"
        selected={this.state.startDate}
        onChange={this.handleStartDateChange}
        autoComplete="off"
        style={{ float: 'left', width: '100%' }}
        placeholderText="Choose Start Date"
      />
    </div>
    <div className="col-lg-2 u-pd-y--4" style={{float:'left',marginTop:"1.5%"}}>
      <DatePicker
        id="EndDate"
        selected={this.state.endDate}
        onChange={this.handleEndDateChange}
        autoComplete="off"
        style={{ float: 'left', width: '100%',  }}
        placeholderText="Choose End Date"
      />
    </div>
    <div className="col-lg-2 u-pd-y--4" style={{float:'right',marginTop:"1.5%"}}>
      <input
        id="SearchByUser"
        className="btn btn-success"
        type="button"
        value="Filter"
        style={{ height: '1.875rem', paddingBlockStart: '0.1875rem',marginLeft:"-33%"}}
        onClick={this.handleFilter}
      />
    </div>
  </div>
</div>







<div className="row">
  <div style={{ marginTop: 20, backgroundColor: 'white', paddingRight: '0px', paddingLeft: '0px' }}>
    {Array.isArray(this.state.tableData) && this.state.tableData.length > 0 ? (
      <BootstrapTable
        striped
        hover
        keyField="WalletTransactionID"
        data={this.state.tableData}
        columns={this.state.columns}
      />
    ) : (
      <div>
        <BootstrapTable
          striped
          hover
          keyField="WalletTransactionID"
          data={this.state.tableData}
          columns={this.state.columns}
        />
        <p>No matching records found</p>
      </div>
    )}
  </div>
</div>

      </div>
  );
}
};
export default WalletComponent;

import React, { Component } from "react";
import axios from "axios";
import swal from "sweetalert";

class RCValidationCheck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RegNo: "", // Use the correct state variable name
      apiResponse: null, // Initialize apiResponse in the state
      error: null, // Initialize error in the state
    };
  }

  handleAadhaarNumberCodeChange = (event) => {
    this.setState({ RegNo: event.target.value }); // Use the correct state variable name
  };

  handleSubmit = () => {
    const { RegNo } = this.state;
    const baseApiUrl = process.env.REACT_APP_API_URL;
    const apiUrl = `${baseApiUrl}RCValidation/CheckRCValidation?RegNo=${RegNo}`;

    axios
      .get(apiUrl)
      .then((response) => {
        if (!response.data) {
          throw new Error("Network response was not ok");
        }

        if(response.data==1)
        {
 
      
           swal({
             title: "Insufficient Balance",
             text: "Please recharge your account.",
             icon: "error",
           });
         }
         else{
 
       
           this.setState({
             apiResponse: response.data,
             error: null,
           });
         }
       })
      .catch((error) => {
        this.setState({
          apiResponse: null,
          error: error.message,
        });
      });
  };

  render() {
    return (
      <div className="row">
        <div className="col">
          <section className="card">
            <header className="card-header">
              <h2 className="card-title" style={{ color: "#0088CC" }}>
                RC Validation(Paid: 5 Credit Charged)
              </h2>
            </header>
            <div className="card-body">
              <div className="form-group row pb-1">
                <div className="col-lg-6">
                  <label htmlFor="txtAadhaarNumber" className="form-label">
                    RC Number
                  </label>
                  <input
                    id="txtRCNumber"
                    type="text"
                    className="form-control"
                    placeholder="Enter Aadhaar Number"
                    style={{ textTransform: "uppercase" }}
                    value={this.state.RegNo}
                    onChange={this.handleAadhaarNumberCodeChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6 offset-lg-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleSubmit}
                  >
                    Verify
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          className="row"
          style={{
            paddingRight: -1,
            marginRight: 387,
            paddingTop: 41,
          }}
        >
          <section className="card">
            <header className="card-header">
              <h2 className="card-title" style={{ color: "#0088CC" }}>
                RC Verification Response
              </h2>
            </header>
            <div className="card-body">
              {this.state.apiResponse !== null && (
                <div className="response-container">
                  <h3>{this.state.apiResponse}</h3>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default RCValidationCheck;

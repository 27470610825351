import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PageHeader from './components/PageHeader';
import EPFValidation from "./components/formcontroll/epfvalidation";
import PANVerification from "./components/formcontroll/panvalidation";
import { Component, useState } from "react";
import AadhaarValidation from "./components/formcontroll/aadhaarvalidation";
import GSTValidation from "./components/formcontroll/gstvalidation";
import RCValidation from "./components/formcontroll/rcvalidation";
import AadhaarValidationCheck from "./components/formcontroll/aadhaarvalidationcheck";
import RCValidationCheck from "./components/formcontroll/rcvalidationcheck";
import AadhaaPanLink from "./components/formcontroll/aadhaar_pan_link";
import DLValidation from './components/formcontroll/dlvalidation';
import DLValidationCheck from './components/formcontroll/dlvalidationcheck';
import RazerPayment from './components/formcontroll/walletrecharge';
import WalletComponent from './components/formcontroll/wallettransactions';
import UANVerification from './components/formcontroll/uanverication';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = { PageHeaderName: 'InstaKYC' };
    }

    render() {
        return (
            <>
                <section role="main" className="content-body">
                    <PageHeader PageHeaderName={this.state.PageHeaderName} />
                    <Routes>
                        <Route index element={<><h1>This is Landing area.</h1></>} />
                        <Route path="/EPFVerification" element={<EPFValidation {...this.props} HeaderName={this.state.PageHeaderName} />} />
                        <Route path="/RazerPayment" element={<RazerPayment {...this.props} HeaderName={this.state.PageHeaderName} />} />
                        <Route path="/wallettransaction" element={<WalletComponent{...this.props}HeaderName={this.state.PageHeaderName}/>}/>
                        <Route path="/PANVerification" element={<PANVerification{...this.props} HeaderName={this.state.PageHeaderName}/> }/>
                        <Route path="/AadhaarValidation" element={<AadhaarValidation{...this.props} HeaderName={this.state.PageHeaderName}/> }/>
                        <Route path="/GSTINValidation" element={<GSTValidation{...this.props}HeaderName={this.state.PageHeaderName}/>}/>
                        <Route path="/RegNoVlaidation" element={<RCValidation{...this.props}HeaderName={this.state.PageHeaderName}/>}/>
                        <Route path="/AadhaarValidationCheck" element={<AadhaarValidationCheck{...this.props}HeaderName={this.state.PageHeaderName}/>}/>
                        <Route path="/RCValidationCheck" element={<RCValidationCheck{...this.props}HeaderName={this.state.PageHeaderName}/>}/>
                        <Route path="/AadhaarPANLinkCheck" element={<AadhaaPanLink{...this.props}HeaderName={this.state.PageHeaderName}/>}/>
                        <Route path="/DLValidation" element={<DLValidation{...this.props}HeaderName={this.state.PageHeaderName}/>}/>
                        <Route path="/DLValidationCheck" element={<DLValidationCheck{...this.props}HeaderName={this.state.PageHeaderName}/>}/>
                        <Route path="/UANVerification" element={<UANVerification{...this.props}HeaderName={this.state.PageHeaderName}/>}/>
                      
                    </Routes>
                </section>
            </>
        );
    }
} 

export default Main;

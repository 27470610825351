import React, { Component } from "react";
import axios from "axios";
import swal from "sweetalert";

class UANVerification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verificationMethod: "uan",
      inputFieldValue: "",
    };
  }

  handleVerificationMethodChange = (event) => {
    this.setState({
      verificationMethod: event.target.value,
      inputFieldValue: "",
    });
  };

  handleInputFieldValueChange = (event) => {
    this.setState({
      inputFieldValue: event.target.value,
    });
  };

  handleVerification = () => {
    debugger;
    const { inputFieldValue, verificationMethod } = this.state;
    const baseApiUrl = process.env.REACT_APP_API_URL;
    let apiUrl;
    
    
    if (verificationMethod === "mobile") {
      apiUrl = `${baseApiUrl}UAN/GetUANwithMobileNumber?mobileNumber=${encodeURIComponent(inputFieldValue)}`;
    } else {
    
        // swal({
        //   title: "Temporary Unavailability",
        //   text: "UAN verification is temporarily unavailable. Please try  with Mobile Number.",
        //   icon: "info",
        // });
      
      apiUrl = `${baseApiUrl}UAN/GetUANwithUANNumber?uanNumber=${encodeURIComponent(inputFieldValue)}`;
    }

    axios.get(apiUrl)
    .then((response) => {
      if (response.data.length === 0) {
        swal({
          title: "Insufficient Balance",
          text: "Please recharge your account.",
          icon: "error",
        });
      } else {
        if (response.data[0].message && response.data[0].message === "No data found") {
          swal({
            title: "No Records Found",
            text: "No records were found for the provided input.",
            icon: "info",
          });
        } else {
          this.setState({
            uanVerificationData: response.data,
            error: null,
          });
        }
      }
    })
    .catch((error) => {
      this.setState({
        uanVerificationData: undefined,
        error: error.message,
      });
    });
};
  renderUANVerificationData = () => {
    debugger;
    const { uanVerificationData } = this.state;

    if (!uanVerificationData) {
      return null;
    }

    // Customize the rendering based on your data structure
    return (
      
      <table className="table">
        <thead>
          <tr>
            <th>Member ID</th>
            <th>Establishment ID</th>  
            <th>Date of Joining</th>
            <th>Date of Exit</th>
            <th>Establishment Name</th>
            <th>Matching UAN</th>
            <th>Mobile</th>
            <th>Name</th>
            <th>Gender</th>
            <th>Is Employed</th>          
            <th>Date of Birth</th>
            <th>Aadhaar Verification Status</th>
            <th>Leave Reason</th>
            {/* Add more headers as needed */}
          </tr>
        </thead>
        <tbody>
          {uanVerificationData.map((data, index) => (
            <tr key={index}>
              <td>{data.member_id}</td>
              <td>{data.establishment_id}</td>
           
              <td>{data.date_of_joining}</td>
              <td>{data.date_of_exit}</td>
              <td>{data.establishment_name}</td>
              <td>{data.matching_uan}</td>
              <td>{data.mobile}</td>
              <td>{data.name}</td>
              <td>{data.gender}</td>
              <td>{String(data.is_employed)}</td>

              

           
              <td>{data.date_of_birth}</td>
          
              <td>{data.aadhaar_verification_status}</td>
              <td>{data.leave_reason}</td>
              {/* Add more cells as needed */}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  
  render() {
    const verificationLabel =
      this.state.verificationMethod === "uan" ? "UAN Number" : "Mobile Number";

    return (
      <div className="row">
        <div className="col">
          <section className="card">
            <header className="card-header">
              <h2 className="card-title" style={{ color: 'rgb(0, 136, 204)' }}>
                UAN/Mobile Number Verification (Paid: 5 credits Charged)
              </h2>
            </header>
          
            <div className="card-body">
              <div className="form-group row pb-1">
                <div className="col-lg-6">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="verificationMethod"
                      id="uanRadio"
                      value="uan"
                      checked={this.state.verificationMethod === "uan"}
                      onChange={this.handleVerificationMethodChange}
                    />
                    <label className="form-check-label" htmlFor="uanRadio">
                      UAN
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="verificationMethod"
                      id="mobileRadio"
                      value="mobile"
                      checked={this.state.verificationMethod === "mobile"}
                      onChange={this.handleVerificationMethodChange}
                    />
                    <label className="form-check-label" htmlFor="mobileRadio">
                      Mobile Number
                    </label>
                  </div>
                  <br />
                  <label htmlFor="verificationInput" className="form-label">
                    {verificationLabel}
                  </label>
                  <input
                    id="verificationInput"
                    type="text"
                    className="form-control"
                    placeholder={`Enter ${verificationLabel}`}
                    style={{ textTransform: 'uppercase' }}
                    value={this.state.inputFieldValue}
                    onChange={this.handleInputFieldValueChange}
                  />
                </div>
                <div className="col-lg-6">
                  {/* Duplicate form-check code removed for brevity */}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6 offset-lg-3">
                  <button
                    type="button"
                    className="btn btn-primary verify-button"
                    onClick={this.handleVerification}
                  >
                    Verify
                  </button>
                </div>
              </div>
            </div>
          </section>
          <div className="row">
            <div className="col">
            
             {this.state.apiResponse !== null && (
              <div className="card-body">
                <div className="response-container">
                <header className="card-header">
                <h2 className="card-title" style={{ color: '#0088cc' }}>
                  UAN/Mobile Verification Response</h2>
                  </header>
                  {this.renderUANVerificationData()}
                </div>
              </div>
            )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UANVerification;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/sidebar';
import Main from './Main';
import InnerWrapper from './components/InnerWrapper';
import GetLoginPage from './components/login';


function App() {
  console.log(process.env.REACT_APP_API_URL_FILTER_WALLET_TRANSACTIONS);
  return (
    <Router>S
      <Routes>
        <Route path="/login" element={<GetLoginPage />} />
        <Route exact path="/*" element={
            <>
              <Header />
              <InnerWrapper Sidebar={<Sidebar />} Main={<Main />} />
            </>
          } />
        </Routes>
    </Router>
  );
}

export default App;
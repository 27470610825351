import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import React from 'react';

import { Link } from "react-router-dom";
import swal from 'sweetalert';

const axios = require('axios');
var txtEmail;
var txtPassword;
var txtMobileNumber;
var txtMobileOTP;
var getOTPBtn;
var LoginBtn;
var emailPasswordMsg;
var emailIDMSG;
var getOTPBtn;
var mobileLoginBtn;
var mobilenumberMSG;
var chkKeepLoggedIN;
var txtMobileOTPMSG;

function GetLoginPage() {

    const navigate = useNavigate();
    if (localStorage.getItem("JWT") !== null)
        navigate('/Dashboard');

    useEffect(() => {


        //document.addEventListener("DOMContentLoaded", () => {
        // $(document).ready(function () {
        //     $(".show_hide_password span").on('click', function (event) {
        //     });
        txtEmail = document.getElementById("txtEmail");
        txtPassword = document.getElementById("txtPassword");
        txtMobileNumber = document.getElementById("txtMobileNumber");
        txtMobileOTP = document.getElementById("txtMobileOTP")
        getOTPBtn = document.getElementById('getOTPBtn');
        LoginBtn = document.getElementById('LoginBtn');
        emailPasswordMsg = document.getElementById('emailPasswordMsg');
        emailIDMSG = document.getElementById('emailIDMSG');
        //document.getElementsByClassName(".show_hide_password span").addEventListener("click", () => { })
        mobileLoginBtn = document.getElementById("mobileLoginBtn");
        getOTPBtn = document.getElementById("getOTPBtn");
        mobilenumberMSG = document.getElementById("mobilenumberMSG");
        chkKeepLoggedIN = document.getElementById('emailStaySignedIn');
        txtMobileOTPMSG = document.getElementById('txtMobileOTPMSG');
        localStorage.setItem('MobileNumber', '');

        //$('#txtMobileNumber').on('keypress', function () {
        //    var length = jQuery('#txtMobileNumber').value.length;
        //    alert(length);
        //    if (length == 10)
        //        $('#getOTPBtn').removeClass('u-disableBlock');
        //    else
        //        $('#getOTPBtn').addClass('u-disableBlock');
        //});

        var activeTab = localStorage.getItem('ActiveTab');
        if (activeTab != "" && activeTab !== null) {
            if (activeTab == "Mobile") {
                document.getElementById("pills-mobile-tab").click();
                // $("#pills-mobile-tab").click();
            }
            if (activeTab == "Email") {
                //$("#pills-email-tab").click();
                document.getElementById("pills-email-tab").click();
            }
        }

        // $("#pills-mobile-tab").on('click', function (event) {
        //     $('#txtEmail').val("");
        //     $('#txtPassword').val("");
        //     localStorage.setItem('ActiveTab', 'Mobile');
        // });

        document.getElementById("pills-mobile-tab").addEventListener("click", () => {
            txtEmail.value = "";
            txtPassword.value = "";
            localStorage.setItem('ActiveTab', 'Mobile');
        })

        // $("#pills-email-tab").on('click', function (event) {
        //     $('#txtMobileNumber').val("");
        //     $('#txtMobileOTP').val("");
        //     localStorage.setItem('ActiveTab', 'Email');
        // });

        document.getElementById("pills-email-tab").addEventListener("click", () => {
            txtMobileNumber.value = "";
            txtMobileOTP.value = "";
            localStorage.setItem('ActiveTab', 'Email');
        })

        // txtMobileNumber.keypress(function (e) {
        //     var length = $(this).value.length;
        //     if (length > 9) {
        //         return false;
        //     } else if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
        //         return false;
        //     } else if ((length == 0) && (e.which == 48)) {
        //         return false;
        //     }
        //     if (length == 9)
        //         $('#getOTPBtn').removeClass('u-disableBlock');
        //     else
        //         $('#getOTPBtn').addClass('u-disableBlock');
        // });

        txtMobileNumber.addEventListener("keypress", (e) => {
            var length = txtMobileNumber.value.length;
            if (length > 9) {
                return false;
            } else if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
                return false;
            } else if ((length == 0) && (e.which == 48)) {
                return false;
            }
            if (length == 9)
                //$('#getOTPBtn').removeClass('u-disableBlock');
                getOTPBtn.classList.remove('u-disableBlock');
            else
                //$('#getOTPBtn').addClass('u-disableBlock');
                getOTPBtn.classList.add('u-disableBlock');

        });
        // });
        // });
    }, []);

    return (
        <>
            <main className="loginv3">
                <section>
                    <a href="index.html">
                        <aside className="loginv3__left">
                            <img src="../img/aboutus/InstaFinancials-Logo-white.svg" alt="Instafinancials logo" />
                        </aside>
                    </a>
                    <aside className="loginv3__right text-center">
                        <div className="loginv3__bg">
                            <img src="../img/newLogIn/small-triangles.svg" className="loginv3__bg--st" alt="background design" />
                            <img src="../img/newLogIn/big-trianles.svg" className="loginv3__bg--bt" alt="background design" />
                        </div>

                        <div className="loginv3__right--inner">
                            <form action="#" method="post" autocomplete="off">
                                <img src="../img/aboutus/InstaFinancials-Logo.svg" alt="Instafinancials logo" width="120" className="u-mg-b--48 u-show-on-mobile" />
                                <div className="d-flex justify-content-between align-content-center">
                                    <h2>Login</h2>
                                    <h4 className="d-flex align-items-center">New user?
                                        <a href="https://instafinancials.com/accounts/Signup.aspx" className="u-text-color-green">&nbsp; Sign up</a>
                                    </h4>
                                </div>

                                {/* <a href="#"  onserverclick="Google_Click" className="loginv3__loginops">
                            <img src="../img/logos/google-color.svg" alt="google icon" />
                            <p>Continue with Google</p>
                        </a> */}
                                {/* <div className="loginv3__or">
                            <span></span>
                            <span>OR</span>
                            <span></span>
                        </div> */}
                                <div className="loginv3__tabs">
                                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                        <li className="nav-item flex-grow-1" role="presentation">

                                            <a className="nav-link active" id="pills-email-tab" data-toggle="pill" href="#pills-email" role="tab" aria-controls="pills-email" aria-selected="false"><i className="fa fa-envelope-o u-mg-r--16" aria-hidden="true"></i>Email</a>
                                        </li>

                                        <li className="nav-item flex-grow-1" role="presentation">
  <a className="nav-link custom-nav-link" id="pills-mobile-tab" data-toggle="pill" href="#pills-mobile" role="tab" aria-controls="pills-mobile" aria-selected="true">
    <i className="fa fa-mobile u-mg-r--16" aria-hidden="true"></i>Mobile
  </a>
</li>


                                    </ul>

                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade" id="pills-mobile" role="tabpanel" aria-labelledby="pills-mobile-tab">
                                            <div className="d-flex u-mg-b--32">
                                                <div className="form-group text-left  u-mg-r--16 loginv3__mcode">
                                                    <label for="phoneCountryCode">Code</label>
                                                    <select className="form-control" id="phoneCountryCode" required disabled="disabled">
                                                        <option>+91</option>
                                                        {/* <%--                                            <option>+92</option>
                                            <option>+93</option>
                                            <option>+94</option>
                                            <option>+95</option>--%> */}
                                                    </select>
                                                </div>
                                                <div className="form-group text-left flex-grow-1">
                                                    <label for="mobilenumber">Phone Number</label>
                                                    <input type="text" className="form-control" id="txtMobileNumber" aria-describedby="mobilenumber" placeholder="e.g. 9900990099" required />

                                                    <div className="input_subtext d-flex justify-content-between">
                                                        <small id="mobilenumberMSG" className="form-text text-danger hidden">Number not registered with InstaFinancials. Please try another
                                                            mobile number.
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="frmgrpOTP" className="hidden">
                                                <div className="form-group text-left flex-grow-1 u-mg-b--32 ">
                                                    <label for="mobileOTP">Enter OTP</label>
                                                    <input type="text" className="form-control" id="txtMobileOTP" aria-describedby="mobileOTP" required />
                                                    <div className="input_subtext d-flex justify-content-between">
                                                        <small id="txtMobileOTPMSG" className="form-text text-danger hidden">
                                                            OTP does not match
                                                        </small>
                                                        <a href="#" className="u-text-color-red ml-auto" onClick={() => GetRegMobileOTP()} >Resend OTP</a>
                                                    </div>

                                                </div>
                                            </div>
                                            <a id="getOTPBtn" href="#" className="if-btn if-btn--grad-green d-block u-mg-b--32" onClick={() => GetRegMobileOTP()}>Get OTP</a>
                                            <a id="mobileLoginBtn" href="#" style={{ display: "none" }} className="hidden if-btn if-btn--grad-green flex-grow-1" onClick={(event) => VerifyOTPLogin(event,navigate)}>Login</a>
                                            {/* <%--<span style="color:red;font-size:13px;font-weight:bold;text-align:left;">
                                        Due to some technical issue with OTP service, we are unable to deliver OTP on your mobile, Please login using email & password.
                                        <br /><br />
                                        Please contact tasneem@instafinancials.com or 90221 54152 for help to reset your password.
                                        </span>--%> */}
                                        </div>
                                        <div className="tab-pane fade active show" id="pills-email" role="tabpanel" aria-labelledby="pills-email-tab">
                                            <div className="form-group text-left flex-grow-1">
                                                <label for="emailID">Email ID</label>
                                                <input type="text" className="form-control" id="txtEmail" onKeyUp={() => checkEmailLogin()} aria-describedby="emailID" placeholder="e.g. sales@instafinancials.com" required />

                                                <div className="input_subtext d-flex justify-content-between">
                                                    <small id="emailIDMSG" className="form-text text-danger u-fs-10 hidden" >Email ID not registered with InstaFinancials. Please try another
                                                        email ID.
                                                    </small>
                                                    <a href="https://instafinancials.com/accounts/manage-login-details.aspx?type=forgot_emailid" className="u-text-color-grey-2 ml-auto u-fs-11 text-right">Forgot Email ID</a>
                                                </div>
                                            </div>
                                            <div className="form-group text-left u-mg-b--32">
                                                <label for="txtPassword">Password</label>
                                                <input type="password" className="form-control" id="txtPassword" onKeyUp={() => checkEmailLogin()} aria-describedby="emailPassword" required />
                                                <div className="input_subtext d-flex justify-content-between">
                                                    <small id="emailPasswordMsg" className="form-text text-danger hidden" >Password does not match
                                                    </small>
                                                    <a href="https://instafinancials.com/accounts/manage-login-details.aspx?type=forgot_password" className="u-text-color-red ml-auto u-fs-11">Forgot Password</a>
                                                </div>

                                            </div>
                                            <div className="d-flex u-flex-column-sm justify-content-between align-items-center u-mg-b--32">
                                                <div className="form-check d-flex align-items-center flex-grow-1 u-mg-sm-b--32">
                                                    <input className="form-check-input" type="checkbox" id="emailStaySignedIn" />
                                                    <label className="form-check-label u-pd-l--8" for="emailStaySignedIn">
                                                        Stay Signed In
                                                    </label>
                                                </div>
                                                <a href="#" id="LoginBtn" onClick={(event) => VerifyUserEmail(event, navigate)} className="if-btn if-btn--grad-green flex-grow-1">Login</a>
                                                {/* <%--<asp:Button ID="btnSubmt"  Text="Sign In" OnClientClick="LoginPreCheck(this)" className="if-btn if-btn--grad-green u-disableBlock flex-grow-1" OnClick="btnLogin_Click" />--%> */}
                                            </div>
                                            {/* <asp:HiddenField ID="hdnAPage"  /> */}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </aside>
                </section>
            </main>
        </>
    );
}

function checkEmailLogin() {
    var email = txtEmail.value;
    var psw = txtPassword.value;
    if (email.length > 8 && psw.length > 5) {
        LoginBtn.classList.remove('u-disableBlock');
    }
    else {
        LoginBtn.classList.add('u-disableBlock');
    }
}

function LoginPreCheck(instance) {

    try {

        var email = txtEmail.value;
        var psw = txtPassword.value;
        if (email.toString().trim() == "" || psw.toString().trim() == "") {
            emailPasswordMsg.innerText = 'Invalid Credentials';
            emailPasswordMsg.classList.remove('hidden');
            return false;
        }
        var emailOrMobile = '';
        //if (txtMobileNumber.value != '') {
        //    emailOrMobile = txtMobileNumber.value;
        //}
        if (txtEmail.value != '') {
            emailOrMobile = txtEmail.value;
        }
        //sendinblue.identify(emailOrMobile);
    }
    catch (e) {
        console.log(e);
    }
}

function ValidateMobile(value) {
    var mobRejex = /^[1-9]{1}[0-9]{9}$/;
    if (mobRejex.test(value)) {
        return true;
    }
    else {
        return false;
    }
}

function ValidateOTP(value) {
    var mobRejex = /^[0-9]{4}$/;
    if (mobRejex.test(value)) {
        return true;
    }
    else {
        return false;
    }
}
function GetRegMobileOTP() {
    var MobileNo = txtMobileNumber.value;
    localStorage.setItem('MobileNumber', MobileNo);

    if (MobileNo != undefined && MobileNo != "") {
        var mobValid = ValidateMobile(MobileNo)
        if (!mobValid) {
            mobilenumberMSG.innerText = "Enter registered 10 digit Mobile Number.";
            mobilenumberMSG.classList.remove('hidden');
        }
        else {
            if (MobileNo.length == 10) {
                const header = {
                    headers: {
                        'react-access-key': process.env.REACT_APP_API_SECRET,
                    }
                }
                axios.get(
                    process.env.REACT_APP_API_URL + '/v1/User/SendOTP/MobileNo/' + MobileNo,
                    header
                )
                    .then((res) => {
                        if (res.data.IsSuccess === true) {
                            mobilenumberMSG.classList.add('hidden');
                            swal({
                                title: "OTP Sent"
                                , text: "Mobile OTP Sent Successfully."
                                , icon: "success"
                                ,
                            }).then((value) => {
                                // Show enter OTP textField and Submit OTP button
                                getOTPBtn.style = "display: none !important;";
                                document.getElementById("frmgrpOTP").classList.remove("hidden");

                                mobileLoginBtn.style.display = "block";
                                mobileLoginBtn.classList.remove("hidden");
                            });
                        }
                        else if (res.data.IsSuccess === false && res.data.Message === "Number not registered with InstaFinancials. Please try another mobile number.") {
                            mobilenumberMSG.innerText = "Number not registered with InstaFinancials. Please try another mobile number.";
                            mobilenumberMSG.classList.remove('hidden');
                        }
                        else if (res.data.IsSuccess === false && res.data.Message === "Unauthorized request for Mobile OTP.") {
                            swal({
                                title: "Failed To Send OTP"
                                , text: "Unauthorized request for Mobile OTP."
                                , icon: "failure"
                                ,
                            })
                        }
                        else {
                            mobilenumberMSG.innerText = res.data.Message;
                            mobilenumberMSG.classList.remove('hidden');

                        }

                    }).catch((err) => {
                        console.log(err)
                        swal({
                            title: "Failed To Send OTP"
                            , text: "Unknown error occured while seding OTP to registered Mobile No.\r\nKindly contact at Support@Instafinancials.com."
                            , icon: "failure"
                        });
                    });
            }
            else {
                mobilenumberMSG.innerText = "Enter registered 10 digit Mobile Number.";
                mobilenumberMSG.classList.remove('hidden');
            }
        }
    }
    else {
        mobilenumberMSG.innerText = "Enter registered 10 digit Mobile Number.";
        mobilenumberMSG.classList.remove('hidden');
    }
    return false;
}

function VerifyOTPLogin(event, navigate) {

    var mobileNo = txtMobileNumber.value;
    var otp = txtMobileOTP.value;
    if (!ValidateMobile(mobileNo)) {
        mobilenumberMSG.innerText = "Enter registered 10 digit Mobile Number.";
        mobilenumberMSG.classList.remove('hidden');
    }
    else if (!ValidateOTP(otp) || otp.length < 4) {
        txtMobileOTPMSG.innerText = "Enter 4 digit OTP received on your registered Mobile No.";
        txtMobileOTPMSG.classList.remove('hidden');

    }
    else {
        var postData = { MobileNo: mobileNo, OTP: otp, KeepLoggedIn: chkKeepLoggedIN.checked };
        axios.post(
            process.env.REACT_APP_API_URL + '/v1/User/VerifyUserOTP'
            , postData
        )
            .then((res) => {
                if (res.data !== undefined && res.data !== null) {
                    if (res.data.IsSuccess === true && res.data.IsSuccess !== undefined && res.data.IsSuccess !== null) {
                        localStorage.setItem("JWT", res.data.JWT);
                        localStorage.setItem("UserName",res.data.UserName);
                        navigate('/Dashboard');
                    }
                    else
                    {
                        txtMobileOTPMSG.innerText = res.data.Message ;
                        txtMobileOTPMSG.classList.remove('hidden');
                    }
                }
                else {
                        txtMobileOTPMSG.innerText = res.data.Message ;
                        txtMobileOTPMSG.classList.remove('hidden');
                }
            }).catch((err) => {
                console.log(err)
                swal({
                    title: "Login Failed"
                    , text: "Unknown error occured while Login.\r\nKindly contact at Support@Instafinancials.com."
                    , icon: "failure"
                });
            });
    }
    event.preventDefault();
}

function VerifyUserEmail(event, navigate) {

    var email = txtEmail.value;
    var password = txtPassword.value;
    if (email && password) {
        var postData = { Email: email, Password: password, KeepLoggedIn: chkKeepLoggedIN.checked };
        axios.post(
            process.env.REACT_APP_API_URL + '/v1/User/VerifyUserLogin'
            , postData
        )
            .then((res) => {
                if (res.data !== undefined && res.data !== null) {
                    localStorage.setItem("JWT", res.data.JWT);
                    localStorage.setItem("UserName",res.data.UserName);
                    navigate('/Dashboard');
                }
                else {
                    emailPasswordMsg.innerText = "Invalid Credentials, Try Again";
                    emailPasswordMsg.classList.remove('hidden');
                }
            }).catch((err) => {
                console.log(err)
                swal({
                    title: "Login Failed"
                    , text: "Unknown error occured while Login.\r\nKindly contact at Support@Instafinancials.com."
                    , icon: "failure"
                });
            });
    }
    else if (email === undefined || email === null || email === "") {
        emailIDMSG.innerText = "please provide your registered email id";
        emailIDMSG.classList.remove('hidden');
    }
    else if (password == undefined || password === null || password === "") {
        emailPasswordMsg.innerText = "please provide password.";
        emailPasswordMsg.classList.remove('hidden');
    }

    event.preventDefault();
}

export default GetLoginPage;
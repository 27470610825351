import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
class EPFValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchType: 'Company', // Default search type
      EPFCode: '',
      EPFName: '',
    };
  }

  handleSearchTypeChange = (event) => {
    this.setState({ searchType: event.target.value });
  };

  handleEPFCodeChange = (event) => {
    this.setState({ EPFCode: event.target.value });
  };

  handleEPFNameChange = (event) => {
    this.setState({ EPFName: event.target.value });
  };

  handleSubmit = () => {
    debugger;
    const baseApiUrl = process.env.REACT_APP_API_URL;
    const { EPFCode, EPFName } = this.state;
    const apiUrl = `${baseApiUrl}EPFO/GetEPFODetails?EPFCode=${EPFCode}&EPFName=${EPFName}`;

    axios
      .get(apiUrl)
      .then(response => {
        if (!response.data) {
          throw new Error('Network response was not ok');
        }
        
        if(response.data==1)
        {
 
      
           swal({
             title: "Insufficient Balance",
             text: "Please recharge your account.",
             icon: "error",
           });
         }
         else{
 
       
           this.setState({
             apiResponse: response.data,
             error: null,
           });
         }
       })
      .catch(error => {
        this.setState({
          apiResponse: null,
          error: error.message,
        });
      });
  };
  render() {
    return (
      <div className="row">
        <div className="col">
          <section className="card">
            <header className="card-header">
              <h2 className="card-title" style={{ color: '#0088CC' }}>
                EPF Verification (Paid: 5 Credit Charged)
              </h2>
            </header>
            <div className="card-body">
              <div className="form-group row pb-1">
                <label className="col-lg-3 control-label text-lg-end pt-2">
                  Input Search Type
                </label>
                <div className="col-lg-8">
                  <div
                    className="radio"
                    style={{ textAlign: 'left', margin: 10, padding: 2 }}
                  >
                    <label style={{ marginRight: 5 }}>
                      <input
                        style={{ marginRight: 5 }}
                        type="radio"
                        name="optionsRadios"
                        value="Company"
                        checked={this.state.searchType === 'Company'}
                        onChange={this.handleSearchTypeChange}
                      />
                      Company
                    </label>
                    {/* Add similar code for LLP */}
                  </div>
                </div>
              </div>
              <div className="form-group row pb-1">
                <div className="col-lg-6">
                  <label htmlFor="txtEPFCode" className="form-label">
                    EPF Code
                  </label>
                  <input
                    id="txtEPFCode"
                    type="text"
                    className="form-control"
                    placeholder="Enter EPF Code"
                    style={{ textTransform: 'uppercase' }}
                    value={this.state.EPFCode}
                    onChange={this.handleEPFCodeChange}
                  />
                </div>
                <div className="col-lg-6">
                  <label htmlFor="txtEPFName" className="form-label">
                    EPF Name
                  </label>
                  <input
                    id="txtEPFName"
                    type="text"
                    className="form-control"
                    placeholder="Enter EPF Name"
                    style={{ textTransform: 'uppercase' }}
                    value={this.state.EPFName}
                    onChange={this.handleEPFNameChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6 offset-lg-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ marginLeft: 325 }}
                    onClick={this.handleSubmit}
                  >
                    Verify
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div classname="row" style={{ paddingRight: -1, marginRight: 387, paddingTop: 41 }}>
          <section className="card">
            <header className="card-header">
              <h2 className="card-title" style={{ color: '#0088CC' }}>
                EPF Verification Response 
              </h2>
            </header>
            <div className="card-body">
              {this.state.apiResponse !== undefined && (
                <div className="response-container">
                <h3>{this.state.apiResponse}</h3>
                </div>
              )}
             
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default EPFValidation;
